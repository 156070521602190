"use client";
import { Alert, AlertDescription } from "@open/hoose/ui/alert";
import { Button } from "@open/hoose/ui/button";
import { Card, CardTitle } from "@open/hoose/ui/card";
import { toast } from "@open/hoose/ui/sonner";
import { Wobble } from "@open/hoose/ui/wobble";
import { OpenLogo } from "app/_components/OpenLogo";
import { OAuth2ProviderU } from "lib/auth.types";
import { AlertTriangle } from "lucide-react";
import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import React, { useState } from "react";

function GoogleIcon() {
  return (
    <svg
      width="20"
      height="20"
      fill="currentColor"
      className="size-6"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z"></path>
    </svg>
  );
}

function GithubIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="size-6"
      viewBox="0 0 1792 1792"
    >
      <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z"></path>
    </svg>
  );
}

function LoginBox() {
  const searchParams = useSearchParams();
  const error = searchParams?.get("error");
  const [loading, setLoading] = useState<Record<OAuth2ProviderU, boolean>>({
    google: false,
    github: false,
    hubspot: false,
    "azure-ad": false,
  });

  const handleSignin = (provider: OAuth2ProviderU) => {
    return async () => {
      setLoading({ ...loading, [provider]: true });
      try {
        await signIn(provider);
      } catch {
        toast.error("Failed to sign in", {
          description: "Please try again",
        });
      } finally {
        setLoading({ ...loading, [provider]: false });
      }
    };
  };

  return (
    <Card className="relative flex flex-col items-center justify-center w-[450px] space-y-8 border-none bg-background/60 backdrop-blur-xl p-8">
      <Wobble>
        <div className="p-6 bg-primary border shadow-lg rounded-3xl mt-4">
          <OpenLogo className="h-8 text-primary-foreground" />
        </div>
      </Wobble>
      <CardTitle className="text-4xl font-bold bg-gradient-to-r from-primary to-primary-foreground bg-clip-text text-transparent">
        Welcome Back
      </CardTitle>
      <p className="text-muted-foreground text-center">
        Sign in to your account to continue where you left off
      </p>
      {error && (
        <Alert variant="destructive" className="animate-shake">
          <AlertDescription className="flex items-center gap-2">
            <AlertTriangle className="h-4 w-4" />
            <span>{error}</span>
          </AlertDescription>
        </Alert>
      )}
      <div className="grid w-full gap-3">
        <Button
          size="lg"
          loading={loading.google}
          className="gap-2 hover:scale-105 transition-transform"
          onClick={async () => {
            await handleSignin("google")();
          }}
          variant="destructive"
        >
          <GoogleIcon />
          <span>Continue with Google</span>
        </Button>

        <Button
          size="lg"
          loading={loading.github}
          className="gap-2 hover:scale-105 transition-transform"
          onClick={async () => {
            await handleSignin("github")();
          }}
        >
          <GithubIcon />
          <span>Continue with Github</span>
        </Button>

        <Button
          size="lg"
          className="gap-2 hover:scale-105 transition-transform"
          variant="outline"
          loading={loading.hubspot}
          onClick={async () => {
            await handleSignin("hubspot")();
          }}
        >
          <svg
            className="size-6 text-orange-600 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <title>HubSpot icon</title>
              <path d="M18.164 7.931V5.085a2.198 2.198 0 0 0 1.266-1.978V3.04A2.199 2.199 0 0 0 17.238.847h-.067a2.199 2.199 0 0 0-2.193 2.192v.067a2.196 2.196 0 0 0 1.252 1.973l.013.006v2.852a6.22 6.22 0 0 0-2.969 1.31l.012-.009-7.828-6.096a2.497 2.497 0 1 0-1.157 1.515l-.012.006 7.696 5.991a6.176 6.176 0 0 0-1.038 3.446c0 1.343.425 2.588 1.147 3.606l-.013-.019-2.342 2.342a1.968 1.968 0 0 0-.58-.095h-.002a2.033 2.033 0 1 0 2.033 2.033 1.978 1.978 0 0 0-.099-.595l.004.014 2.317-2.317a6.247 6.247 0 1 0 4.782-11.133l-.036-.005zm-.964 9.377a3.206 3.206 0 1 1 3.214-3.206v.002a3.206 3.206 0 0 1-3.206 3.206z"></path>
            </g>
          </svg>
          <span>Continue with Hubspot</span>
        </Button>

        <Button
          size="lg"
          className="gap-2 hover:scale-105 transition-transform"
          variant="outline"
          loading={loading["azure-ad"]}
          onClick={async () => {
            await handleSignin("azure-ad")();
          }}
        >
          <svg
            height="20"
            width="20"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 291.346 291.346"
            xmlSpace="preserve"
          >
            <g>
              <path
                style={{ fill: "#26A6D1" }}
                d="M117.547,266.156L0,249.141v-94.296h117.547V266.156z"
              />
              <path
                style={{ fill: "#3DB39E" }}
                d="M291.346,136.51H136.31l0.055-114.06L291.346,0.009V136.51z"
              />
              <path
                style={{ fill: "#F4B459" }}
                d="M291.346,291.337l-155.091-22.459l0.182-114.015h154.909V291.337z"
              />
              <path
                style={{ fill: "#E2574C" }}
                d="M117.547,136.51H0V42.205l117.547-17.024V136.51z"
              />
            </g>
          </svg>
          <span>Continue with Azure</span>
        </Button>
      </div>
    </Card>
  );
}

export default function LoginPage() {
  return (
    <div className="relative min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-background via-muted to-background overflow-hidden">
      {/* Grid pattern overlay */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />

      <LoginBox />
    </div>
  );
}
